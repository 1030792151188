import React from 'react'
import Layout from '../components/Layout'
import SmallHeader from '../components/SmallHeader'
import Footer from '../components/Footer'

const ContactPage = () => (
  <Layout path="/contact" title="Contact - Brentiv - Rental Software">
    <div className="contact-page">
      <SmallHeader
        title="Contact with us"
        subtitle="Our team is happy to answer all your questions. Fill out the form and we'll be in touch as soon as possible."
      />

      <div className="row-spaced contact-page-content">
        <div className="container">
          <div className="row">
            <div className="col-md-8 ml-auto mr-auto">
              <form
                action="https://formspree.io/support@brentiv.com"
                method="POST"
              >
                <input type="hidden" name="_next" value="https://brentiv.com" />
                <input type="text" name="_gotcha" style={{ display: 'none' }} />
                <div className="form-group">
                  <label htmlFor="contactName">Name</label>
                  <input
                    id="contactName"
                    className="form-control"
                    type="text"
                    name="name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contactEmail">Email address</label>
                  <input
                    id="contactEmail"
                    className="form-control"
                    type="email"
                    name="_replyto"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="contactMessage">Message</label>
                  <textarea
                    id="contactMessage"
                    className="form-control"
                    name="message"
                    required
                  />
                </div>
                <button className="btn btn-primary" type="submit">
                  Send message
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </Layout>
)

export default ContactPage
